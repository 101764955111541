/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateGroupPost = /* GraphQL */ `subscription OnCreateGroupPost {
  onCreateGroupPost {
    postId
    groupName
    playerSub
    player {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    contentType
    content
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGroupPostSubscriptionVariables,
  APITypes.OnCreateGroupPostSubscription
>;
