import React, { useContext } from "react";
import { View, FlatList, TouchableHighlight } from "react-native";
import {
  Text,
  ListItem,
  Avatar,
  ThemeContext,
  FAB,
  Divider,
} from "@rneui/themed";
import { StackScreenProps } from "@react-navigation/stack";
import { GroupContext, PlayerContext } from "../../contexts";
import { GroupsTabParamList } from "../../navigation/GroupsNavigator";
import { GroupPlayer } from "../../API";
import { useStyles } from "../../styles";

type Props = StackScreenProps<GroupsTabParamList, "My Groups">;

export function Groups({ navigation, route }: Props) {
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  const groupDetails = React.useContext(GroupContext);
  const playerDetails = React.useContext(PlayerContext);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);

  const onPress = (groupName: string) => {
    groupDetails?.updateGroup(groupName);
    navigation.navigate("Group", { groupName: groupName });
  };

  function acronym(text: string): string {
    return text
      .split(/\s/)
      .reduce((accumulator, word) => accumulator + word.charAt(0), "");
  }

  async function refresh(): Promise<void> {
    setIsRefreshing(true);
    await playerDetails?.updatePlayer();
    setIsRefreshing(false);
  }

  const FlatListItemSeparator = () => {
    return <Divider orientation="horizontal" color={theme.colors.grey3} />;
  };

  const renderItem = ({ item }: { item: GroupPlayer }) => (
    <ListItem
      pad={20}
      Component={TouchableHighlight}
      onPress={() => onPress(item.groupName)}
    >
      <View>
        <Avatar
          size={64}
          title={acronym(item.groupName).substring(0, 2)}
          rounded
          containerStyle={{ backgroundColor: theme.colors.black }}
          titleStyle={{ color: theme.colors.white }}
        />
      </View>
      <ListItem.Content>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <ListItem.Title>
            <Text h3 adjustsFontSizeToFit={true} numberOfLines={1}>
              {item.groupName}
            </Text>
          </ListItem.Title>
        </View>
      </ListItem.Content>
      <ListItem.Chevron />
    </ListItem>
  );

  return (
    <View style={globalStyles.container}>
      <FlatList
        data={playerDetails?.player?.groups?.items || []}
        renderItem={renderItem}
        style={{
          flex: 1,
          alignSelf: "center",
          width: "100%",
          maxWidth: 600,
          margin: 10,
        }}
        onRefresh={refresh}
        refreshing={isRefreshing}
        ItemSeparatorComponent={FlatListItemSeparator}
      ></FlatList>
      {playerDetails?.player?.groups?.items?.length || 0 < 5 ? (
        <FAB
          visible={true}
          icon={{ name: "add", color: "white" }}
          placement="right"
          size="large"
          onPress={() => {
            navigation.navigate("GroupNew");
          }}
          color={theme.colors.primary}
        />
      ) : undefined}
    </View>
  );
}
