import React, { useEffect, useState, useCallback, useContext } from "react";
import { GroupContext, PlayerContext } from "../../contexts";
import { View } from "react-native";
import { GroupPost, ModelGroupPostConnection } from "../../API";
import { generateClient } from 'aws-amplify/api';
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { GroupTabParamList } from "../../navigation/GroupsNavigator";
import { StackScreenProps } from "@react-navigation/stack";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";
import { ThemeContext } from "@rneui/themed";
import Observable from "zen-observable-ts";
import { useStyles } from "../../styles";
import { GiftedChat, Bubble, InputToolbar } from "react-native-gifted-chat";
import { SafeAreaView } from "react-native-safe-area-context";


const client = generateClient();

type Props = StackScreenProps<GroupTabParamList, "Feed">;

export function GroupChat({ navigation, route }: Props) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [nextToken, setNextToken] = useState<string | null>();
  const groupDetails = React.useContext(GroupContext);
  const playerDetails = React.useContext(PlayerContext);
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);

  const updateMessages = useCallback(async () => {
    if (route.params.groupName !== undefined) {
      const result = (await client.graphql({
        query: queries.listGroupPosts,
        variables: {
          groupName: route.params.groupName,
        },
      })) as GraphQLResult<any>;
      const listGroupPosts = result.data
        .listGroupPosts as ModelGroupPostConnection;

      setMessages(
        listGroupPosts.items?.map((post) => {
          return {
            _id: post.postId,
            text: post.content,
            createdAt: post.createdAt,
            user: {
              _id: post.playerSub,
              name: post.player.username,
            },
          };
        }) || []
      );
      setNextToken(listGroupPosts.nextToken);
    }
  }, [route.params.groupName]);

  const getEarlierMessages = async () => {
    setIsFetching(true);
    if (nextToken !== null) {
      const result = (await client.graphql({
        query: queries.listGroupPosts,
        variables: {
          groupName: groupDetails?.group?.name || "",
          nextToken: nextToken,
        }
      })) as GraphQLResult<any>;
      const listGroupPosts = result.data
        .listGroupPosts as ModelGroupPostConnection;
      setNextToken(listGroupPosts.nextToken);
      const earlierMessages =
        listGroupPosts.items?.map((post) => {
          return {
            _id: post.postId,
            text: post.content,
            createdAt: post.createdAt,
            user: {
              _id: post.playerSub,
              name: post.player.username,
            },
          };
        }) || [];
      setMessages([...messages, ...earlierMessages]);
      setIsFetching(false);
    }
  };

  async function postMessages(messages: any[]) {
    for (let message of messages) {
      try {
        await client.graphql({
          query: mutations.createGroupPost,
          variables: {
            input: {
              content: message.text,
              groupName: groupDetails?.group?.name || "",
            },
          }
        }
        );
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (messages.length === 0) {
      updateMessages();
    }
    const subscription = (
      client.graphql({
        query: subscriptions.onCreateGroupPost
      }) as Observable<object>
    ).subscribe({
      next: (data: any) => {
        const newMessage = data.value.data.onCreateGroupPost as GroupPost;
        setMessages([
          {
            _id: newMessage.postId,
            text: newMessage.content,
            createdAt: newMessage.createdAt,
            user: {
              _id: newMessage.playerSub,
              name: newMessage.player.username,
            },
          },
          ...messages,
        ]);
      },
      error: (err) => console.log("err", err.error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [updateMessages, messages, theme.colors.grey5]);

  function renderBubble(props: any) {
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: {
            backgroundColor: theme.colors.grey5,
          },
        }}
        textStyle={{
          left: {
            color: theme.colors.black,
          },
        }}
      />
    );
  }

  function renderInputToolbar(props: any) {
    return (
      <InputToolbar
        {...props}
        containerStyle={{ backgroundColor: theme.colors.background }}
        textInputStyle={{ color: theme.colors.black }}
      />
    );
  }

  return (
    <View style={{ flex: 1, marginLeft: 5, marginRight: 5 }}>
      <GiftedChat
        messages={messages}
        loadEarlier={true}
        renderUsernameOnMessage={true}
        onLoadEarlier={getEarlierMessages}
        onSend={(messages) => postMessages(messages)}
        renderBubble={renderBubble}
        renderInputToolbar={renderInputToolbar}
        isLoadingEarlier={isFetching}
        user={{
          _id: playerDetails?.player?.sub || "",
        }}
      />
    </View>
  );
}
