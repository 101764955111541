import { ThemeOptions, makeStyles } from "@rneui/themed";


export const useStyles = makeStyles((theme: ThemeOptions) => ({
  container: {
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    paddingBottom: 3,
  },
  listItem: {
    borderColor: theme.colors.grey5,
    borderWidth: 2,
  },
  hit: {
    backgroundColor: theme.colors.success,
  },
  miss: {
    backgroundColor: theme.colors.grey1,
  },
  hitMiss: {
    backgroundColor: theme.colors.warning,
  },
  circle: {
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    backgroundColor: theme.colors.black,
    justifyContent: "center",
    marginRight: 20,
  },
  circleText: {
    color: theme.colors.white,
    textAlign: "center"
  },
  input: {
    margin: 10,
  }
}));
