import React, { createElement } from "react";
import { Text, Button } from "@rneui/themed";
import { View, Platform } from "react-native";
import DateTimePicker, {
  DateTimePickerEvent,
} from "@react-native-community/datetimepicker";
import { ScoreManualStyles } from "../../styles/ScoreManual";
import { useThemeMode } from "@rneui/themed";

interface DataPickerWebProps {
  value: Date;
  onChange: any;
}

function DataPickerWeb({ value, onChange }: DataPickerWebProps) {
  return createElement("input", {
    type: "date",
    value: value,
    onInput: onChange,
  });
}

export function DateStep({
  startDate,
  setStartDate,
  nextStep,
}: {
  startDate: string;
  setStartDate: any;
  nextStep: any;
}) {
  const { mode, setMode } = useThemeMode();

  const DatePickerNative = () => {
    if (Platform.OS === "web") {
      return createElement("input", {
        type: "date",
        value: startDate,
        onInput: (event: any) => {
          setStartDate(event.target.value);
        },
      });
    }
    return (
      <DateTimePicker
        value={new Date(startDate)}
        themeVariant={mode}
        onChange={(event: DateTimePickerEvent, date: Date | undefined) =>
          setStartDate((date || new Date).toISOString().substring(0, 10))
        }
        maximumDate={new Date()}
        minimumDate={new Date("2021-06-19")}
      />
    );
  };

  return (
    <>
      <View style={ScoreManualStyles.stepHeader}>
        <Text h2>Date</Text>
      </View>
      <View style={[ScoreManualStyles.stepContent, {}]}>
          <DatePickerNative></DatePickerNative>
      </View>
      <View style={ScoreManualStyles.stepFooter}>
        <Button onPress={() => nextStep()}>Next</Button>
      </View>
    </>
  );
}
