import React, { useEffect, useState, useCallback, useContext } from "react";
import { GroupContext, PlayerContext } from "../../contexts";
import {
  View,
  FlatList,
  StyleSheet,
  FlexAlignType,
  KeyboardAvoidingView,
  Platform,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  GestureResponderEvent,
  TextInputContentSizeChangeEventData,
} from "react-native";
import { GroupPost, ModelGroupPostConnection } from "../../API";
import { generateClient } from 'aws-amplify/api';
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { GroupStackParamList } from "../../navigation/GroupsNavigator";
import { StackScreenProps } from "@react-navigation/stack";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";
import { Text, Card, Input, Icon, ThemeContext } from "@rneui/themed";
import Observable from "zen-observable-ts";
import { useStyles } from "../../styles";

type Props = StackScreenProps<GroupStackParamList, "GroupPosts">;

const client = generateClient();

interface inputStyle {
  height: number;
  viewHeight: number;
}

export function GroupPosts({ navigation, route }: Props) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [posts, setPosts] = useState<GroupPost[]>([]);
  const [nextToken, setNextToken] = useState<string | null>();
  const [content, setContent] = useState<string>("");
  const [state, setState] = useState<inputStyle>({
    height: 35,
    viewHeight: 35,
  });
  const groupDetails = React.useContext(GroupContext);
  const playerDetails = React.useContext(PlayerContext);
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);

  const udpateGroupPosts = useCallback(async () => {
    if (groupDetails?.group?.name !== undefined) {
      const result = (await client.graphql({
        query: queries.listGroupPosts,
        variables: {
          groupName: groupDetails?.group?.name,
        }
      })) as GraphQLResult<any>;
      const listGroupPosts = result.data
        .listGroupPosts as ModelGroupPostConnection;
      setPosts(listGroupPosts.items as GroupPost[]);
      setNextToken(listGroupPosts.nextToken);
    }
  }, [groupDetails?.group?.name]);

  const concatGroupPosts = async () => {
    setIsFetching(true);
    if (nextToken !== null) {
      const result = (await client.graphql({
        query: queries.listGroupPosts,
        variables: {
          groupName: groupDetails?.group?.name,
          nextToken: nextToken,
        }}
      )) as GraphQLResult<any>;
      setNextToken(result.data?.listGroupPosts.nextToken);
      setPosts([
        ...posts,
        ...(result.data.listGroupPosts.items as GroupPost[]),
      ]);
    }
    setIsFetching(false);
  };

  function onContentInputChange(
    e: NativeSyntheticEvent<TextInputChangeEventData>
  ) {
    setContent(e.nativeEvent.text);
  }

  function onContentSizeChange(
    e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) {
    if (state.height <= 35 * 6) {
      setState({
        height: e.nativeEvent.contentSize.height,
        viewHeight: e.nativeEvent.contentSize.height,
      });
    }
  }

  async function onSubmit(e: GestureResponderEvent) {
    e.preventDefault();
    if (content !== "") {
      try {
        await client.graphql({
          query: mutations.createGroupPost,
          variables: {input: {
            content: content,
            groupName: groupDetails?.group?.name,
          }},
        });
        setContent("");
      } catch (error: any) {}
    }
  }

  useEffect(() => {
    if (posts.length === 0) {
      udpateGroupPosts();
    }
    const subscription = (
      client.graphql({
        query: subscriptions.onCreateGroupPost
      }) as Observable<object>
    ).subscribe({
      next: (data: any) => {
        const newPost = data.value.data.onCreateGroupPost as GroupPost;
        setPosts([newPost, ...posts]);
      },
      error: (err) => console.log(`connection error: ${err.error.errors}`),
      complete: () => {
        console.log("connect complete");
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [udpateGroupPosts, posts]);

  function Item({ post }: { post: GroupPost }) {
    let alignItems: FlexAlignType = "flex-start";
    if (post.playerSub === playerDetails?.player?.sub) {
      alignItems = "flex-end";
    }

    return (
      <View style={[styles.cardChat, { flex: 1, alignItems: alignItems }]}>
        <Text>{post.player?.username}</Text>
        <Card
          containerStyle={[
            {
              flexDirection: "column",
              marginHorizontal: 0,
              marginVertical: 0,
              maxWidth: "75%",
            },
          ]}
        >
          <Text>{post.content}</Text>
        </Card>
      </View>
    );
  }

  const renderItem = ({ item }: { item: GroupPost }) => <Item post={item} />;

  return (
    <View style={{flex: 1}}>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1 }}
      >
        <View style={globalStyles.container}>
          <View
            style={{
              flex: 1,
              width: "100%",
              marginLeft: 10,
              marginRight: 10,
              maxWidth: 1000,
            }}
          >
            <FlatList
              data={posts || []}
              renderItem={renderItem}
              keyExtractor={(item: GroupPost) => String(item.postId)}
              style={{ flex: 1, marginBottom: 2, alignSelf: "stretch" }}
              onEndReached={() => concatGroupPosts()}
              onRefresh={() => udpateGroupPosts()}
              refreshing={isFetching}
            ></FlatList>
          </View>
          <View
            style={{
              flexDirection: "row",
              width: "98%",
              borderRadius: 10,
              borderWidth: 2,
              padding: 0,
            }}
          >
            <Input
              value={content}
              placeholder="Message"
              multiline={true}
              inputStyle={[
                {
                  height: Math.max(35, state.height),
                  paddingRight: 30,
                },
                // @ts-ignore
                Platform.select({
                  web: {
                    outlineStyle: "none",
                  },
                }),
              ]}
              inputContainerStyle={[
                {
                  alignItems: "flex-start",
                  margin: 0,
                  padding: 0,
                },
              ]}
              rightIcon={
                <Icon
                  name="arrow-circle-up"
                  size={30}
                  containerStyle={{
                    position: "absolute",
                    right: 0,
                    marginLeft: 10,
                  }}
                  type="font-awesome-5"
                  onPress={(e) => onSubmit(e)}
                />
              }
              onChange={(e) => onContentInputChange(e)}
              onContentSizeChange={(e) => onContentSizeChange(e)}
            />
          </View>
        </View>
      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  cardChat: {
    marginVertical: 8,
    marginHorizontal: 16,
  },
});
