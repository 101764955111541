import React, { useContext } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Distribution, Player } from "../API";
import { Text, ThemeContext } from "@rneui/themed";
import { useStyles } from "../styles";

export function PlayerStastics({ player }: { player: Player | undefined }) {
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);

  const distribution: Distribution =
    player?.scoreOverall?.distribution ||
    ({
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      six: 0,
      misses: 0,
    } as Distribution);

  let high: number = 0;
  if (distribution.one > high) {
    high = distribution.one;
  }
  if (distribution.two > high) {
    high = distribution.two;
  }
  if (distribution.three > high) {
    high = distribution.three;
  }
  if (distribution.four > high) {
    high = distribution.four;
  }
  if (distribution.five > high) {
    high = distribution.five;
  }
  if (distribution.six > high) {
    high = distribution.six;
  }

  const distributions: { width: string; backgroundColor: string }[] = [];
  distributions.push({
    width: `${(distribution.one / high) * 90 || 8}%`,
    backgroundColor: distribution.one === high ? "green" : "gray",
  });
  distributions.push({
    width: `${(distribution.two / high) * 90 || 8}%`,
    backgroundColor: distribution.two === high ? "green" : "gray",
  });
  distributions.push({
    width: `${(distribution.three / high) * 90 || 8}%`,
    backgroundColor: distribution.three === high ? "green" : "gray",
  });
  distributions.push({
    width: `${(distribution.four / high) * 90 || 8}%`,
    backgroundColor: distribution.four === high ? "green" : "gray",
  });
  distributions.push({
    width: `${(distribution.five / high) * 90 || 8}%`,
    backgroundColor: distribution.five === high ? "green" : "gray",
  });
  distributions.push({
    width: `${(distribution.six / high) * 90 || 8}%`,
    backgroundColor: distribution.six === high ? "green" : "gray",
  });

  const winPercent =
    ((distribution.one +
      distribution.two +
      distribution.three +
      distribution.four +
      distribution.five +
      distribution.six) /
      (player?.scoreOverall?.rounds || 1)) *
    100;

  return (
    <ScrollView contentContainerStyle={globalStyles.container}>
      <View style={styles.stastisticSection}>
        <Text h4 adjustsFontSizeToFit>
          {player?.username}'s Statistics
        </Text>
        <View style={styles.statisticHeader}>
          <View style={styles.statisticElement}>
            <Text h3 h3Style={{ textAlign: "center" }}>
              {player?.scoreOverall?.rounds}
            </Text>
            <Text style={{ textAlign: "center" }}>Played</Text>
          </View>
          <View style={styles.statisticElement}>
            <Text h3 h3Style={{ textAlign: "center" }}>
              {winPercent.toFixed(0)}
            </Text>
            <Text style={[{ textAlign: "center" }]}>Win %</Text>
          </View>
          <View style={styles.statisticElement}>
            <Text h3 h3Style={{ textAlign: "center" }}>
              {player?.scoreOverall?.currentStreak}
            </Text>
            <Text style={[{ textAlign: "center" }]}>Current Streak</Text>
          </View>
          <View style={styles.statisticElement}>
            <Text h3 h3Style={{ textAlign: "center" }}>
              {player?.scoreOverall?.maxStreak}
            </Text>
            <Text style={[{ textAlign: "center" }]}>Max Streak</Text>
          </View>
        </View>
      </View>
      <View style={styles.distributionSection}>
        <View style={{ alignItems: "center" }}>
          <Text h4 adjustsFontSizeToFit>
            Guess Distribution
          </Text>
        </View>
        <View style={styles.distribution}>
          <View style={styles.distributionElement}>
            <View style={{ marginRight: 10 }}>
              <Text h4>1</Text>
            </View>
            <View style={[styles.rectangle, distributions[0]]}>
              <Text h4 h4Style={styles.distributionNumber}>
                {distribution.one}
              </Text>
            </View>
          </View>
          <View style={styles.distributionElement}>
            <View style={{ marginRight: 10 }}>
              <Text h4>2</Text>
            </View>
            <View style={[styles.rectangle, distributions[1]]}>
              <Text h4 h4Style={styles.distributionNumber}>
                {distribution.two}
              </Text>
            </View>
          </View>
          <View style={styles.distributionElement}>
            <View style={{ marginRight: 10 }}>
              <Text h4>3</Text>
            </View>
            <View style={[styles.rectangle, distributions[2]]}>
              <Text h4 h4Style={styles.distributionNumber}>
                {distribution.three}
              </Text>
            </View>
          </View>
          <View style={styles.distributionElement}>
            <View style={{ marginRight: 10 }}>
              <Text h4>4</Text>
            </View>
            <View style={[styles.rectangle, distributions[3]]}>
              <Text h4 h4Style={styles.distributionNumber}>
                {distribution.four}
              </Text>
            </View>
          </View>
          <View style={styles.distributionElement}>
            <View style={{ marginRight: 10 }}>
              <Text h4>5</Text>
            </View>
            <View style={[styles.rectangle, distributions[4]]}>
              <Text h4 h4Style={styles.distributionNumber}>
                {distribution.five}
              </Text>
            </View>
          </View>
          <View style={styles.distributionElement}>
            <View style={{ marginRight: 10 }}>
              <Text h4>6</Text>
            </View>
            <View style={[styles.rectangle, distributions[5]]}>
              <Text h4 h4Style={styles.distributionNumber}>
                {distribution.six}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  stastisticSection: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
    minHeight: 120
  },
  distributionSection: {
    flex: 4,
    flexDirection: "column",
    margin: 10,
    maxWidth: 600,
    minHeight: 250,
    width: "100%",
    marginBottom: 0,
  },
  statisticHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    flex: 1,
    width: "100%",
    margin: 10,
  },
  distribution: {
    flexDirection: "column",
    alignItems: "baseline",
    flex: 1,
    width: "100%",
  },
  statisticElement: {
    flex: 1,
    padding: 10,
    flexDirection: "column",
  },
  distributionElement: {
    flexDirection: "row",
    alignItems: "center",
    padding: 3,
    flex: 1,
    width: "100%",
  },
  distributionNumber: {
    textAlign: "right",
    paddingRight: 10,
    color: "white",
  },
  rectangle: {
    backgroundColor: "gray",
    width: 30,
    height: "100%",
    alignContent: "flex-end",
    justifyContent: "center",
  },
});
