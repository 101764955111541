import React from "react";
import { GroupPlayerContext } from "../../contexts";
import { PlayerScoreHistory } from "../../components";

export function GroupPlayerScoreHistory() {
  const playerDetails = React.useContext(GroupPlayerContext);
  
  return (
    <PlayerScoreHistory sub={playerDetails?.player?.sub || ""}/>
  );
}

