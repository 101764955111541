import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, {useContext} from "react";
import { Profile, ScoreManual } from "../screens";
import { ThemeContext } from '@rneui/themed';
import { UserName } from '../screens/Profile/Username';
import { Notifications } from '../screens/Profile/Notifications';


export type ProfileStackParamList = {
  Profile: undefined;
  ScoreManual: undefined;
  UserName: undefined;
  Notifications: undefined;
};

const Stack = createNativeStackNavigator<ProfileStackParamList>();

export function ProfileNavigator() {
  const { theme } = useContext(ThemeContext);

  return (
    <Stack.Navigator screenOptions={{
      headerTitleAlign: "center",
      headerStyle: { backgroundColor: theme.colors.background },
      headerTitleStyle: { color: theme.colors.black },
      contentStyle: {
        backgroundColor: theme.colors.background,
      },
    }}>
      <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="UserName" component={UserName} />
      <Stack.Screen name="ScoreManual" component={ScoreManual} />
      <Stack.Screen name="Notifications" component={Notifications} />
    </Stack.Navigator>
  );
}
