import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, {useContext,} from "react";
import { HomeNavigator } from "./HomeNavigator";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { GroupsNavigator } from "./GroupsNavigator";
import { ProfileNavigator } from "./ProfileNavigator";
import { PostScore } from "../screens";
import { ThemeContext } from "@rneui/themed";

const Tab = createBottomTabNavigator();


export function AppNavigator() {
  const { theme } = useContext(ThemeContext);
  
    return (
      <Tab.Navigator
        screenOptions={({ route }: { route: any }) => ({
          headerShown: false,
          tabBarStyle: {
            backgroundColor: theme.colors.background,
          },
          headerTitleAlign: "center",
          headerStyle: {
            backgroundColor: theme.colors.background,
          },
          headerTitleStyle: {
            color: theme.colors.black,
          }
        })}
        sceneContainerStyle={{
          backgroundColor: theme.colors.background,
          flex: 1,
        }}
      >
        <Tab.Screen
          name="HomeTab"
          component={HomeNavigator}
          options={{
            tabBarLabel: "Home",
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="home" color={color} size={size} />
            ),
          }}
        />
  
        <Tab.Screen
          name="GroupsTab"
          component={GroupsNavigator}
          options={{
            tabBarLabel: "Groups",
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="account-group"
                color={color}
                size={size}
              />
            ),
          }}
        />
  
        <Tab.Screen
          name="PostScore"
          component={PostScore}
          options={{
            headerShown: true,
            tabBarLabel: "Post",
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="plus-circle"
                color={color}
                size={size}
              />
            ),
          }}
        />
  
        <Tab.Screen
          name="ProfileTab"
          component={ProfileNavigator}
          options={{
            tabBarLabel: "Profile",
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="account-settings"
                color={color}
                size={size}
              />
            ),
          }}
        />
      </Tab.Navigator>
    );
  
}
