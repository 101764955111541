import React, {useContext} from "react";
import { View, StyleSheet } from "react-native";
import { ScoreDailyAttempt, ScoreDailyAttempts } from "../API";
import { useStyles } from "../styles";
import { ThemeContext } from "@rneui/themed";

export function PlayerScoreDaily({ score }: { score: ScoreDailyAttempts }) {
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  
  function attemptRender(input: string) {
    switch (input) {
      case "Hit": {
        return <View style={[styles.rectangle, globalStyles.hit]}></View>
        //return <Icon name="square-full" type="font-awesome-5" style={{margin: .5, flex: 1}}></Icon>
      }
      case "Miss": {
        return <View style={[styles.rectangle, globalStyles.miss]}></View>
        //return <Icon name="square-full" type="font-awesome-5" color="green" style={{margin: .5, flex: 1}}></Icon>
      }
      case "HitMiss": {
        return <View style={[styles.rectangle, globalStyles.hitMiss]}></View>
        //return <Icon name="square-full" type="font-awesome-5" color= "yellow" style={{margin: .5, flex: 1}}></Icon>
      }
    }
  }

  const Attempt = ({ attempt }: { attempt: ScoreDailyAttempt }) => {
    return (
      <View style={styles.tableRow}>
        {attemptRender(attempt.one)}
        {attemptRender(attempt.two)}
        {attemptRender(attempt.three)}
        {attemptRender(attempt.four)}
        {attemptRender(attempt.five)}
      </View>
    );
  };

  return (
    <View style={styles.table}>
      <Attempt attempt={score.one} />
      {score.two !== undefined && score.two !== null ? (
        <Attempt attempt={score.two} />
      ) : null}
      {score.three !== undefined && score.three !== null ? (
        <Attempt attempt={score.three} />
      ) : null}
      {score.four !== undefined && score.four !== null ? (
        <Attempt attempt={score.four} />
      ) : null}
      {score.five !== undefined && score.five !== null ? (
        <Attempt attempt={score.five} />
      ) : null}
      {score.six !== undefined && score.six !== null ? (
        <Attempt attempt={score.six} />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  table: {
    alignItems: "stretch",
    alignContent: "stretch",
    flex: 1,
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "stretch",
    alignContent: "stretch",
    flex: 1,
  },
  rectangle: {
    flex: 1,
    margin: .5,
    alignItems: "stretch",
    alignSelf: "stretch",
    aspectRatio: 1,
  },
  hit: {
    backgroundColor: "green",
  },
  miss: {
    backgroundColor: "black",
  },
  hitMiss: {
    backgroundColor: "yellow",
  },
});
