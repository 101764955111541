import React from "react";
import { GroupPlayerContext } from "../../contexts";
import { PlayerStastics } from "../../components";

export function GroupPlayerStastics() {
  const playerDetails = React.useContext(GroupPlayerContext);
  
  return (
    <PlayerStastics player={playerDetails?.player} />
  );
}

