import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React, { useContext } from "react";
import {
  Groups,
  LeaderBoard,
  GroupPlayerStastics,
  GroupPlayerScoreHistory,
  LeaderBoardMonthly,
  Invites,
} from "../screens";
import { GroupPlayerProvider, GroupProvider, GroupContext } from "../contexts";
import { ThemeContext } from "@rneui/themed";
import { StackScreenProps } from "@react-navigation/stack";
import { GroupChat } from "../screens/Group/GroupChat";
import { GroupNew } from "../screens/Group/GroupNew";
import { GroupPlayerInvite } from "../screens/Group/GroupPlayerInvite";

export type GroupStackParamList = {
  Groups: undefined;
  Group: { groupName: string };
  GroupPlayer: { userName: string };
  GroupPosts: undefined;
  GroupChat: undefined;
  GroupNew: undefined;
  GroupPlayerInvite: { groupName: string };
};

export type GroupTabParamList = {
  Leaderboard: undefined;
  Monthly: { groupName: string };
  Feed: { groupName: string };
  Overall: undefined;
  Daily: undefined;
};

export type GroupsTabParamList = {
  "My Groups": undefined;
  "My Invites": undefined;
};

const Stack = createNativeStackNavigator<GroupStackParamList>();
const Tab = createMaterialTopTabNavigator<GroupTabParamList>();
const TabGroups = createMaterialTopTabNavigator<GroupsTabParamList>();

export function GroupsNavigator() {
  const groupDetails = React.useContext(GroupContext);
  const { theme } = useContext(ThemeContext);

  return (
    <GroupProvider>
      <GroupPlayerProvider>
        <Stack.Navigator
          screenOptions={{
            headerTitleAlign: "center",
            headerStyle: { backgroundColor: theme.colors.background },
            headerTitleStyle: { color: theme.colors.black },
            contentStyle: {
              backgroundColor: theme.colors.background,
            },
          }}
        >
          <Stack.Screen name="Groups" component={GroupsTabNavigator} />
          <Stack.Screen
            name="Group"
            component={GroupTabNavigator}
            options={({ route }) => ({ title: route.params.groupName })}
          />
          <Stack.Screen
            name="GroupPlayer"
            component={GroupPlayerTabNavigator}
            options={({ route }) => ({ title: route.params.userName })}
          />
          <Stack.Screen
            name="GroupNew"
            component={GroupNew}
            options={({}) => ({ title: "New Group" })}
          />
          <Stack.Screen
            name="GroupPlayerInvite"
            component={GroupPlayerInvite}
            options={({ route }) => ({ title: route.params.groupName })}
          />
        </Stack.Navigator>
      </GroupPlayerProvider>
    </GroupProvider>
  );
}

type GroupsTabNavigatorProps = StackScreenProps<GroupStackParamList, "Groups">;

function GroupsTabNavigator({ navigation, route }: GroupsTabNavigatorProps) {
  const { theme } = useContext(ThemeContext);

  return (
    <TabGroups.Navigator
      screenOptions={({ route }: { route: any }) => ({
        headerShown: false,
        tabBarStyle: {
          backgroundColor: theme.colors.background,
        },
        tabBarLabelStyle: {
          color: theme.colors.black,
        },
      })}
      sceneContainerStyle={{
        backgroundColor: theme.colors.background,
      }}
    >
      <TabGroups.Screen name="My Groups" component={Groups}  />
      <TabGroups.Screen name="My Invites" component={Invites} />
    </TabGroups.Navigator>
  );
}

type GroupTabNavigatorProps = StackScreenProps<GroupStackParamList, "Group">;

function GroupTabNavigator({ navigation, route }: GroupTabNavigatorProps) {
  const { theme } = useContext(ThemeContext);

  return (
    <Tab.Navigator
      screenOptions={({ route }: { route: any }) => ({
        headerShown: false,
        tabBarStyle: {
          backgroundColor: theme.colors.background,
        },
        tabBarLabelStyle: {
          color: theme.colors.black,
        },
      })}
      sceneContainerStyle={{
        backgroundColor: theme.colors.background,
      }}
    >
      <Tab.Screen name="Leaderboard" component={LeaderBoard} />
      <Tab.Screen
        name="Monthly"
        component={LeaderBoardMonthly}
        initialParams={{ groupName: route.params.groupName }}
      />
      <Tab.Screen name="Feed" component={GroupChat} initialParams={{ groupName: route.params.groupName }} />
    </Tab.Navigator>
  );
}

function GroupPlayerTabNavigator() {
  const { theme } = useContext(ThemeContext);
  return (
    <Tab.Navigator
      screenOptions={({ route }: { route: any }) => ({
        headerShown: false,
        tabBarStyle: {
          backgroundColor: theme.colors.background,
        },
        tabBarLabelStyle: {
          color: theme.colors.black,
        },
      })}
      sceneContainerStyle={{
        backgroundColor: theme.colors.background,
      }}
    >
      <Tab.Screen name="Overall" component={GroupPlayerStastics} />
      <Tab.Screen name="Daily" component={GroupPlayerScoreHistory} />
    </Tab.Navigator>
  );
}
