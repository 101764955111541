import React, { useEffect } from "react";
import { Text, Button, Input, Divider } from "@rneui/themed";
import { View } from "react-native";
import { ScoreManualStyles } from "../../styles/ScoreManual";
import { NativeSyntheticEvent, TextInputChangeEventData } from "react-native";

export function StatisticsStep({
  score,
  handleChange,
  errors,
  nextStep,
  prevStep,
}: {
  score: any;
  handleChange: any;
  errors: any;
  nextStep: any;
  prevStep: any;
}) {
  return (
    <>
      <View style={ScoreManualStyles.stepHeader}>
        <Text h2>Statistics</Text>
      </View>
      <View style={ScoreManualStyles.stepContent}>
        <View style={{ flex: 0.3 }}>
          <Input
            keyboardType="numeric"
            label="Rounds Played"
            containerStyle={ScoreManualStyles.input}
            renderErrorMessage={errors.rounds?.state || false}
            errorMessage={errors.rounds?.description || ""}
            onChange={(e: any) => handleChange(e, "rounds")}
            value={score.rounds === 0 ? "" : score.rounds.toString()}
          />
        </View>
        <Divider
          style={{ width: "100%", marginTop: 40, marginBottom: 40 }}
          width={2}
        ></Divider>
        <View style={{ flex: 1 }}>
          <Input
            keyboardType="numeric"
            containerStyle={ScoreManualStyles.input}
            label="Current Streak"
            renderErrorMessage={errors.currentStreak?.state || false}
            errorMessage={errors.currentStreak?.description || ""}
            onChange={(e: any) => handleChange(e, "currentStreak")}
            value={
              score.currentStreak === 0 ? "" : score.currentStreak.toString()
            }
          />
          <Input
            keyboardType="numeric"
            containerStyle={ScoreManualStyles.input}
            label="Max Streak"
            renderErrorMessage={errors.maxStreak?.state || false}
            errorMessage={errors.maxStreak?.description || ""}
            onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) =>
              handleChange(e, "maxStreak")
            }
            value={score.maxStreak === 0 ? "" : score.maxStreak.toString()}
          />
        </View>
      </View>

      <View style={ScoreManualStyles.stepFooter}>
        <View style={ScoreManualStyles.stepFooterButtons}>
          <Button onPress={(e) => prevStep()}>Previous</Button>
          <Button onPress={(e) => nextStep()}>Next</Button>
        </View>
      </View>
    </>
  );
}
