import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React, {useContext} from 'react';
import { Home, UserScoreHistory } from '../screens';
import { ThemeContext } from "@rneui/themed";

const Stack = createNativeStackNavigator();
const Tab = createMaterialTopTabNavigator();

export function HomeNavigator() {
  const { theme } = useContext(ThemeContext);

  return (
    <Stack.Navigator
      screenOptions={{
        headerTitleAlign: "center",
        contentStyle: {
          flex: 1,
          backgroundColor: theme.colors.background,
        },
        headerStyle: {
          backgroundColor: theme.colors.background,
        },
        headerTitleStyle: {
          color: theme.colors.black,
        }
      }}
    >
      <Stack.Screen name='Home' component={HomeTabNavigator} />
    </Stack.Navigator>
  );
}

export function HomeTabNavigator() {
  const { theme } = useContext(ThemeContext);

  return (
    <Tab.Navigator
      screenOptions={({ route }: { route: any }) => ({
        headerShown: false,
        tabBarStyle: {
          backgroundColor: theme.colors.background,
        },
        tabBarLabelStyle: {
          color: theme.colors.black,
        }
      })}
      sceneContainerStyle={{
        backgroundColor: theme.colors.background,
      }}
    >
      <Tab.Screen name="Overall" component={Home} />
      <Tab.Screen name="Daily" component={UserScoreHistory} />
    </Tab.Navigator>
  );
}

