const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_e1LqvXuFi",
  "aws_user_pools_web_client_id": "3f0t5s5chjihj2de6o5ns1jlsm",
  "aws_cognito_identity_pool_id": "us-east-1:12cc064d-57af-4265-9602-f55c483335d0",
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_appsync_graphqlEndpoint": "https://rx3idmlmvjed5hqdxizqfgmpdi.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_mobile_analytics_app_id": "af00002d5d734e74945a6f7bb6fafeb5",
  "aws_mobile_analytics_app_region": "us-east-1"
}
          
  export default awsmobile;
  
