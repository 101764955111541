import React, { useState, useEffect, useContext } from "react";
import { Button, ThemeContext, Input } from "@rneui/themed";
import { View, GestureResponderEvent } from "react-native";
import * as mutations from "../../graphql/mutations";
import { StackScreenProps } from "@react-navigation/stack";
import { generateClient } from 'aws-amplify/api';
import { PlayerContext } from "../../contexts";
import { ProfileStackParamList } from "../../navigation/ProfileNavigator";
import { useStyles } from "../../styles";
import { NativeSyntheticEvent, TextInputChangeEventData } from "react-native";

type Props = StackScreenProps<ProfileStackParamList, "UserName">;
const client = generateClient();

export function UserName({ navigation, route }: Props) {
  const playerDetails = React.useContext(PlayerContext);
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  const [ newUserName, setNewUserName] = useState(playerDetails?.player?.username || "Unknown");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {}, [playerDetails?.player?.username]);

  async function onSubmit(e: GestureResponderEvent) {
    e.preventDefault();
    try {
      await client.graphql({
        query: mutations.updatePlayer,
        variables: {
          username: newUserName,
        }
      });
      await playerDetails?.updatePlayer();
      navigation.goBack();
    } catch (error: any) {
      console.log(error);
      setErrorMessage('Error submitting username');
    }
  }

  function handleChange(e: NativeSyntheticEvent<TextInputChangeEventData>) {
    const value = e.nativeEvent.text;
    setNewUserName(value);
  }

  return (
    <View style={[globalStyles.container, { width: "100%" }]}>
      <View
        style={{ flex: 1, flexDirection: "column", width: "98%", maxWidth: 500, marginTop: 10 }}
      >
        <Input
          style={globalStyles.input}
          label="UserName"
          renderErrorMessage={errorMessage != "" ? true : false}
          errorMessage={errorMessage}
          onChange={(e: any) => handleChange(e)}
          value={newUserName}
        />
        <Button onPress={(e: GestureResponderEvent) => onSubmit(e)}>
          Submit
        </Button>
      </View>
    </View>
  );
}
