import React from "react";
import { Text, Button, Divider } from "@rneui/themed";
import { GestureResponderEvent, View } from "react-native";
import { ScoreManualStyles } from "../../styles/ScoreManual";

export function ReviewStep({
  score,
  misses,
  onSubmit,
  prevStep,
}: {
  score: any;
  misses: any;
  onSubmit: any;
  prevStep: any;
}) {
  return (
    <>
      <View style={ScoreManualStyles.stepHeader}>
        <Text h2>Review</Text>
      </View>
      <View style={ScoreManualStyles.stepContent}>
        <Text h4 style={{marginBottom: 20}}>Statistics</Text>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Rounds Played:</Text>
          <Text> {score.rounds}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Current Streak:</Text>
          <Text> {score.currentStreak}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Max Streak:</Text>
          <Text>{score.maxStreak}</Text>
        </View>
        <Divider
          style={{ width: "100%", marginTop: 40, marginBottom: 40 }}
          width={2}
        ></Divider>
        <Text h4 style={{marginBottom: 20}}>Distribution</Text>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>One:</Text>
          <Text> {score.distribution.one}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Two:</Text>
          <Text> {score.distribution.two}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Three:</Text>
          <Text> {score.distribution.three}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Four:</Text>
          <Text> {score.distribution.four}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Five:</Text>
          <Text> {score.distribution.five}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Six:</Text>
          <Text> {score.distribution.six}</Text>
        </View>
        <View style={ScoreManualStyles.reviewItem}>
          <Text style={ScoreManualStyles.reviewLabel}>Misses:</Text>
          <Text> {misses}</Text>
        </View>
      </View>

      <View style={ScoreManualStyles.stepFooter}>
        <View style={ScoreManualStyles.stepFooterButtons}>
          <Button onPress={() => prevStep()}>Previous</Button>
          <Button onPress={(e: GestureResponderEvent) => onSubmit(e)}>Submit</Button>
        </View>
      </View>
    </>
  );
}
