import React from "react";
import { PlayerContext } from "../../contexts";
import { PlayerStastics } from "../../components";

export function Home() {
  const playerDetails = React.useContext(PlayerContext);
  
  return (
    <PlayerStastics player={playerDetails?.player} />
  );
}

