import React, { useState, useEffect } from "react";
import { Platform } from "react-native";
import * as Localization from "expo-localization";
import * as Device from "expo-device";
import { Player } from "../API";
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import * as queries from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import * as Notifications from "expo-notifications";
import { createPlayer } from "../graphql/mutations";
import AsyncStorage from "@react-native-async-storage/async-storage";
// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

interface PlayerContextInterface {
  player: Player | undefined;
  updatePlayer: () => Promise<void>;
}

const client = generateClient();

const PlayerContext = React.createContext<PlayerContextInterface | null>(null);

function PlayerProvider({ children }: { children: any }) {
  const [player, setPlayer] = useState<Player>();

  const registerForPushNotificationsAsync = async () => {
    if (Platform.OS === "ios" && Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync({
          ios: {
            allowAlert: true,
            allowBadge: true,
            allowSound: false,
            allowAnnouncements: true,
          },
        });
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        console.log("Failed to get push token for push notification!");
        return;
      }
    } else {
      console.log("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }
  };

  const updatePinpointEndpoint = async (details: any) => {
    //Analytics.updateEndpoint(details).catch((error: any) => {
    //  console.log(error);
    //});
  };

  const updatePinpoint = async (player: Player) => {
    let notifications: any = [];
    try {
      notifications = JSON.parse(
        (await AsyncStorage.getItem("@notifications")) || "[]"
      );
    } catch {}

    const details = {
      optOut: "NONE",
      attributes: {
        notifications: notifications,
      },
      userId: player.sub,
      userAttributes: {
        username: [player.username],
        sub: [player.sub],
      },
      demographic: {
        timezone: Localization.timezone,
        locale: Localization.locale,
      },
    };

    if (Device.isDevice) {
      Notifications.getDevicePushTokenAsync()
        .then((resultToken) => {
          updatePinpointEndpoint({
            address: resultToken.data,
            ...details,
          });
        })
        .catch(() => {
          updatePinpointEndpoint({
            ...details,
            address: "web",
          });
        });
    } else {
      updatePinpointEndpoint({
        ...details,
        address: "simulator",
      });
    }
  };

  const updatePlayer = async () => {
    const user = await fetchAuthSession();
    if (user.userSub) {
      const result = (await client.graphql({
        query: queries.getPlayer,
        variables: {
          sub: user.userSub,
        }
      })) as GraphQLResult<any>;
      if (result.data.getPlayer === null) {
        console.log("Create user");
        const result = (await client.graphql({
          query: createPlayer,
          variables: {
            username: user.userSub,
          }

        })) as GraphQLResult<any>;
        setPlayer(result.data.getPlayer as Player);
      }
      setPlayer(result.data.getPlayer as Player);
  
      if (result.data.getPlayer !== undefined) {
        await registerForPushNotificationsAsync();
        await updatePinpoint(result.data.getPlayer as Player);
      }
    }
    
    
  };

  useEffect(() => {
    updatePlayer();
  }, []);

  return (
    <PlayerContext.Provider value={{ player, updatePlayer }}>
      {children}
    </PlayerContext.Provider>
  );
}

export { PlayerContext, PlayerProvider };
