/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createGroup = /* GraphQL */ `mutation CreateGroup($name: String!) {
  createGroup(name: $name) {
    name
    ownerSub
    owner {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    scoresOverall {
      nextToken
      startedAt
      items {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    players {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            __typename
          }
          scoresOverall {
            nextToken
            startedAt
            __typename
          }
          players {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const createGroupPlayer = /* GraphQL */ `mutation CreateGroupPlayer($groupName: String!, $playerSub: String!) {
  createGroupPlayer(groupName: $groupName, playerSub: $playerSub) {
    playerSub
    player {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    groupName
    group {
      name
      ownerSub
      owner {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      scoresOverall {
        nextToken
        startedAt
        items {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      players {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupPlayerMutationVariables,
  APITypes.CreateGroupPlayerMutation
>;
export const createPlayer = /* GraphQL */ `mutation CreatePlayer($username: String!) {
  createPlayer(username: $username) {
    sub
    username
    email
    scoreOverall {
      playerSub
      number
      average
      rounds
      currentStreak
      maxStreak
      distribution {
        one
        two
        three
        four
        five
        six
        misses
        __typename
      }
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
    scoreHistory {
      nextToken
      startedAt
      items {
        playerSub
        number
        attemptNumber
        hardMode
        attempts {
          one {
            one
            two
            three
            four
            five
            __typename
          }
          two {
            one
            two
            three
            four
            five
            __typename
          }
          three {
            one
            two
            three
            four
            five
            __typename
          }
          four {
            one
            two
            three
            four
            five
            __typename
          }
          five {
            one
            two
            three
            four
            five
            __typename
          }
          six {
            one
            two
            three
            four
            five
            __typename
          }
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    groups {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            __typename
          }
          scoresOverall {
            nextToken
            startedAt
            __typename
          }
          players {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlayerMutationVariables,
  APITypes.CreatePlayerMutation
>;
export const updatePlayer = /* GraphQL */ `mutation UpdatePlayer($username: String!) {
  updatePlayer(username: $username) {
    sub
    username
    email
    scoreOverall {
      playerSub
      number
      average
      rounds
      currentStreak
      maxStreak
      distribution {
        one
        two
        three
        four
        five
        six
        misses
        __typename
      }
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
    scoreHistory {
      nextToken
      startedAt
      items {
        playerSub
        number
        attemptNumber
        hardMode
        attempts {
          one {
            one
            two
            three
            four
            five
            __typename
          }
          two {
            one
            two
            three
            four
            five
            __typename
          }
          three {
            one
            two
            three
            four
            five
            __typename
          }
          four {
            one
            two
            three
            four
            five
            __typename
          }
          five {
            one
            two
            three
            four
            five
            __typename
          }
          six {
            one
            two
            three
            four
            five
            __typename
          }
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    groups {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            __typename
          }
          scoresOverall {
            nextToken
            startedAt
            __typename
          }
          players {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlayerMutationVariables,
  APITypes.UpdatePlayerMutation
>;
export const createScore = /* GraphQL */ `mutation CreateScore($score: String!) {
  createScore(score: $score) {
    playerSub
    number
    average
    rounds
    currentStreak
    maxStreak
    distribution {
      one
      two
      three
      four
      five
      six
      misses
      __typename
    }
    player {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScoreMutationVariables,
  APITypes.CreateScoreMutation
>;
export const createGroupPost = /* GraphQL */ `mutation CreateGroupPost($input: GroupPostInput!) {
  createGroupPost(input: $input) {
    postId
    groupName
    playerSub
    player {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    contentType
    content
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupPostMutationVariables,
  APITypes.CreateGroupPostMutation
>;
export const createScoreManual = /* GraphQL */ `mutation CreateScoreManual($score: ScoreInput!) {
  createScoreManual(score: $score) {
    playerSub
    number
    average
    rounds
    currentStreak
    maxStreak
    distribution {
      one
      two
      three
      four
      five
      six
      misses
      __typename
    }
    player {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScoreManualMutationVariables,
  APITypes.CreateScoreManualMutation
>;
export const createGroupPlayerScoreMonthly = /* GraphQL */ `mutation CreateGroupPlayerScoreMonthly($score: GroupPlayerScoreMonthlyInput!) {
  createGroupPlayerScoreMonthly(score: $score) {
    groupName
    playerSub
    month
    averageOverall
    averagePosted
    rounds
    player {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupPlayerScoreMonthlyMutationVariables,
  APITypes.CreateGroupPlayerScoreMonthlyMutation
>;
export const createGroupInvite = /* GraphQL */ `mutation CreateGroupInvite($groupName: String!, $playerEmail: String!) {
  createGroupInvite(groupName: $groupName, playerEmail: $playerEmail) {
    playerSub
    player {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    groupName
    group {
      name
      ownerSub
      owner {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      scoresOverall {
        nextToken
        startedAt
        items {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      players {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupInviteMutationVariables,
  APITypes.CreateGroupInviteMutation
>;
export const acceptGroupInvite = /* GraphQL */ `mutation AcceptGroupInvite($groupName: String!) {
  acceptGroupInvite(groupName: $groupName) {
    name
    ownerSub
    owner {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    scoresOverall {
      nextToken
      startedAt
      items {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    players {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            __typename
          }
          scoresOverall {
            nextToken
            startedAt
            __typename
          }
          players {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AcceptGroupInviteMutationVariables,
  APITypes.AcceptGroupInviteMutation
>;
