import React, { useEffect, useContext } from "react";
import { View, Dimensions, FlatList } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { Picker } from "@react-native-picker/picker";
import { GroupPlayerContext } from "../../contexts";
import { GroupTabParamList } from "../../navigation/GroupsNavigator";
import { TouchableHighlight } from "react-native-gesture-handler";
import {
  Text,
  ListItem,
  ThemeContext,
  Avatar,
  Divider,
  Button,
  Dialog,
} from "@rneui/themed";
import { useStyles } from "../../styles";
import { generateClient } from 'aws-amplify/api';
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import {
  ModelGroupPlayerScoreMonthly,
  GroupPlayerScoreMonthly,
} from "../../API";

const client = generateClient();

type Props = StackScreenProps<GroupTabParamList, "Monthly">;

interface PlayerLeaderBoard {
  score: GroupPlayerScoreMonthly;
  place: number;
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function LeaderBoardMonthly({ navigation, route }: Props) {
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  const groupPlayerDetails = React.useContext(GroupPlayerContext);
  const [leaderBoard, setLeaderBoard] = React.useState<PlayerLeaderBoard[]>([]);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);
  const [dialogMonthVisible, setDialogMonthVisible] = React.useState(false);
  const [dialogYearVisible, setDialogYearVisible] = React.useState(false);
  let now: Date = new Date();
  const [year, setYear] = React.useState((now.getFullYear().toString()));
  const [month, setMonth] = React.useState((("0" + (now.getMonth() + 1)).slice(-2)));

  let i = 0;

  const onPress = (sub: string, userName: string) => {
    groupPlayerDetails?.updatePlayer(sub);
    navigation.navigate("GroupPlayer", { userName: userName });
  };

  const updateLeaderBoard = async () => {
    setIsRefreshing(true);
    setLeaderBoard([]);

    const result = (await client.graphql({
      query: queries.listGroupScoresMonthly,
      variables: {
        groupName: route.params.groupName,
        month: `${year}-${month}`,
      }
    })) as GraphQLResult<any>;

    const scores = result.data
      .listGroupScoresMonthly as ModelGroupPlayerScoreMonthly;

    let lastScore = 0;
    let lastPostition = 0;
    let lb: PlayerLeaderBoard[] = [];
    (
      (scores.items || []).sort(
        (a: GroupPlayerScoreMonthly, b: GroupPlayerScoreMonthly) =>
          a.averageOverall < b.averageOverall ? -1 : 1
      ) || []
    ).forEach((value: GroupPlayerScoreMonthly, index: number) => {
      if (lastScore != value.averageOverall) {
        lastPostition = index + 1;
        lastScore = value.averageOverall;
      }
      lb = lb.concat({ score: value, place: lastPostition });
    });
    setLeaderBoard(lb);
    setIsRefreshing(false);
  };

  useEffect(() => {
    updateLeaderBoard();
  }, [route.params.groupName, year, month]);

  const renderItem = ({ item }: { item: PlayerLeaderBoard }) => {
    return (
      <ListItem
        pad={20}
        key={item.score.playerSub}
        onPress={() =>
          onPress(item.score.playerSub, item.score.player?.username || "")
        }
        Component={TouchableHighlight}
      >
        <ListItem.Content style={{ flex: 2 }}>
          <Avatar
            size={32}
            title={item.place.toString()}
            rounded
            containerStyle={{ backgroundColor: theme.colors.primary }}
            titleStyle={{ color: theme.colors.white }}
          />
        </ListItem.Content>
        <ListItem.Content style={{ flex: 6 }}>
          <ListItem.Title>
            <Text adjustsFontSizeToFit={true}>
              {item.score.player?.username}
            </Text>
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text adjustsFontSizeToFit={true}>
              {item.score.averageOverall.toFixed(3)}
            </Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text adjustsFontSizeToFit={true}>{item.score.rounds}</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 0.5 }}>
          <ListItem.Chevron />
        </ListItem.Content>
      </ListItem>
    );
  };

  const renderHeader = ({}: {}) => {
    return (
      <ListItem pad={20}>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Title>
            <Text>#</Text>
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 6 }}>
          <ListItem.Title>
            <Text>User</Text>
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text>Avg.</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text>Rds</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 0.5 }}>
          <ListItem.Chevron color={theme.colors.background} />
        </ListItem.Content>
      </ListItem>
    );
  };

  const FlatListItemSeparator = () => {
    return <Divider orientation="horizontal" color={theme.colors.grey3} />;
  };

  const toggleDialogMonth = () => {
    setDialogMonthVisible(!dialogMonthVisible);
  };

  const toggleDialogYear = () => {
    setDialogYearVisible(!dialogYearVisible);
  };

  return (
    <View style={globalStyles.container}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          margin: 10,
        }}
      >
        <View style={{ flex: 0.5 }} />
        <View style={{ flex: 1 }}>
          <Button title={year} onPress={toggleDialogYear} />
        </View>
        <View style={{ flex: 0.5 }} />
        <View style={{ flex: 1 }}>
          <Button
            title={monthNames[parseInt(month) - 1]}
            onPress={toggleDialogMonth}
          />
        </View>
        <View style={{ flex: 0.5 }} />
      </View>
      <FlatList
        data={leaderBoard || []}
        renderItem={renderItem}
        keyExtractor={(item: PlayerLeaderBoard) => String(item.score.playerSub)}
        style={{
          flex: 1,
          alignSelf: "center",
          width: "100%",
          maxWidth: 600,
          margin: 10,
        }}
        ListHeaderComponent={renderHeader}
        refreshing={isRefreshing}
        onRefresh={() => updateLeaderBoard()}
        ItemSeparatorComponent={FlatListItemSeparator}
      ></FlatList>
      <Dialog isVisible={dialogYearVisible} onBackdropPress={toggleDialogYear}>
        <Text>Select month: </Text>
        <Picker
          style={{}}
          itemStyle={{}}
          selectedValue={year}
          onValueChange={(itemValue, itemIndex) => setYear(itemValue)}
        >
          <Picker.Item label="2022" value="2022" />
          <Picker.Item label="2023" value="2023" />
        </Picker>
        <Button title="Select" onPress={toggleDialogYear} />
      </Dialog>
      <Dialog
        isVisible={dialogMonthVisible}
        onBackdropPress={toggleDialogMonth}
      >
        <Text>Select month: </Text>
        <Picker
          style={{}}
          itemStyle={{}}
          selectedValue={month}
          onValueChange={(itemValue, itemIndex) => setMonth(itemValue)}
        >
          <Picker.Item label="January" value="01" />
          <Picker.Item label="February" value="02" />
          <Picker.Item label="March" value="03" />
          <Picker.Item label="April" value="04" />
          <Picker.Item label="May" value="05" />
          <Picker.Item label="June" value="06" />
          <Picker.Item label="July" value="07" />
          <Picker.Item label="August" value="08" />
          <Picker.Item label="September" value="09" />
          <Picker.Item label="October" value="10" />
          <Picker.Item label="November" value="11" />
          <Picker.Item label="December" value="12" />
        </Picker>
        <Button title="Select" onPress={toggleDialogMonth} />
      </Dialog>
    </View>
  );
}
