import React, { useEffect, useContext } from "react";
import { View, Dimensions } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import {
  GroupContext,
  GroupPlayerContext,
  PlayerContext,
} from "../../contexts";
import { GroupTabParamList } from "../../navigation/GroupsNavigator";
import { Score } from "../../API";
import { FlatList, TouchableHighlight } from "react-native-gesture-handler";
import { Text, ListItem, ThemeContext, Avatar, SpeedDial, Divider } from "@rneui/themed";
import { useStyles } from "../../styles";

type Props = StackScreenProps<GroupTabParamList, "Leaderboard">;

interface PlayerLeaderBoard {
  score: Score;
  index: number;
}

export function LeaderBoard({ navigation, route }: Props) {
  const { theme } = useContext(ThemeContext);
  const [speedDialOpen, setSpeedDialOpen] = React.useState(false);
  const globalStyles = useStyles(theme);
  const groupDetails = React.useContext(GroupContext);
  const playerDetails = React.useContext(PlayerContext);
  const groupPlayerDetails = React.useContext(GroupPlayerContext);
  const [leaderBoard, setLeaderBoard] = React.useState<PlayerLeaderBoard[]>([]);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);
  let i = 0;

  const onPress = (sub: string, userName: string) => {
    groupPlayerDetails?.updatePlayer(sub);
    navigation.navigate("GroupPlayer", { userName: userName });
  };

  useEffect(() => {
    setLeaderBoard([]);
    let lb: PlayerLeaderBoard[] = [];
    (
      (groupDetails?.group?.scoresOverall?.items || []).sort(
        (a: Score, b: Score) => (a.average < b.average ? -1 : 1)
      ) || []
    ).forEach((value: Score, index: number) => {
      lb = lb.concat({ score: value, index: index + 1 });
    });
    setLeaderBoard(lb);
  }, [groupDetails]);

  async function refresh(): Promise<void> {
    setIsRefreshing(true);
    await playerDetails?.updatePlayer();
    setIsRefreshing(false);
  }

  const FlatListItemSeparator = () => {
    return <Divider orientation="horizontal" color={theme.colors.grey3} />;
  };

  const renderItem = ({ item }: { item: PlayerLeaderBoard }) => {
    return (
      <ListItem
        pad={20}
        key={item.score.playerSub}
        onPress={() =>
          onPress(item.score.playerSub, item.score.player?.username || "")
        }
        Component={TouchableHighlight}
      >
        <ListItem.Content style={{ flex: 2 }}>
          <Avatar
            size={32}
            title={item.index.toString()}
            rounded
            containerStyle={{ backgroundColor: theme.colors.primary }}
            titleStyle={{ color: theme.colors.white }}
          />
        </ListItem.Content>
        <ListItem.Content style={{ flex: 6 }}>
          <ListItem.Title>
            <Text adjustsFontSizeToFit={true}>
              {item.score.player?.username}
            </Text>
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text adjustsFontSizeToFit={true}>
              {item.score.average.toFixed(3)}
            </Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text adjustsFontSizeToFit={true}>{item.score.rounds}</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 0.5 }}>
          <ListItem.Chevron />
        </ListItem.Content>
      </ListItem>
    );
  };

  const renderHeader = ({}: {}) => {
    return (
      <ListItem
        pad={20}
      >
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Title>
            <Text>#</Text>
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 6 }}>
          <ListItem.Title>
            <Text>User</Text>
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text>Avg.</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 2 }}>
          <ListItem.Subtitle>
            <Text>Rds</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content style={{ flex: 0.5 }}>
          <ListItem.Chevron color={theme.colors.background} />
        </ListItem.Content>
      </ListItem>
    );
  };

  return (
    <View style={globalStyles.container}>
      <FlatList
        data={leaderBoard || []}
        renderItem={renderItem}
        keyExtractor={(item: PlayerLeaderBoard) => String(item.index)}
        ListHeaderComponent={renderHeader}
        style={{
          flex: 1,
          alignSelf: "center",
          width: "100%",
          maxWidth: 600,
          margin: 10,
        }}
        onRefresh={refresh}
        refreshing={isRefreshing}
        ItemSeparatorComponent={FlatListItemSeparator}
      ></FlatList>
      {groupDetails?.group?.ownerSub === playerDetails?.player?.sub ? (
        <SpeedDial
          isOpen={speedDialOpen}
          icon={{ name: "edit" }}
          openIcon={{ name: "close" }}
          onOpen={() => setSpeedDialOpen(!speedDialOpen)}
          onClose={() => setSpeedDialOpen(!speedDialOpen)}
          color={theme.colors.primary}
        >
          <SpeedDial.Action
            icon={{ name: "add" }}
            title="Invite Player"
            onPress={() =>
              navigation.navigate("GroupPlayerInvite", {
                groupName: groupDetails?.group?.name || "Unknown",
              })
            }
            color={theme.colors.primary}
          />
          <SpeedDial.Action
            icon={{ name: "delete" }}
            title="Remove Player"
            onPress={() => console.log("Delete Something")}
            color={theme.colors.primary}
          />
        </SpeedDial>
      ) : undefined}
    </View>
  );
}
