import React, { useContext } from "react";
import { Amplify } from "aws-amplify";
// @ts-ignore
//import { withAuthenticator, AmplifyTheme } from "aws-amplify-react-native";
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react-native';
import awsconfig from "./src/aws-exports";
import { RootNavigator } from "./src/navigation";
import { PlayerProvider } from "./src/contexts";
import { createTheme, ThemeProvider, ThemeContext } from "@rneui/themed";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { initializePushNotifications } from 'aws-amplify/push-notifications';
import { Platform } from "react-native";


Amplify.configure({
  ...awsconfig,
  //Analytics: {
    //disabled: false,
    //AWSPinpoint: {
    //  appId: awsconfig.aws_mobile_analytics_app_id,
    //  region: awsconfig.aws_mobile_analytics_app_region,
    //},
  //},
  Analytics: {
    Pinpoint: {
        appId: awsconfig.aws_mobile_analytics_app_id,
        region: awsconfig.aws_mobile_analytics_app_region,
    },
  },
  Notifications: {
    PushNotification: {
      Pinpoint: {
        appId: awsconfig.aws_mobile_analytics_app_id,
        region: awsconfig.aws_mobile_analytics_app_region,
      },
      
    }
  }
}
);

if (Platform.OS != "web") {
  initializePushNotifications();
}

declare module "@rneui/themed" {
  export interface Colors {
    primaryLight: string;
    primaryDark: string;
    secondaryLight: string;
    secondaryDark: string;
  }
}

const theme = createTheme({});

function App() {
  return (
    <SafeAreaProvider>
      <PlayerProvider>
        <RootNavigator />
      </PlayerProvider>
    </SafeAreaProvider>
  );
}

function AppWithAuth() {
  const { theme } = useContext(ThemeContext);

  //const amplifyTheme = {
  //  ...AmplifyTheme,
  //  button: {
  //    ...AmplifyTheme.button,
  //    backgroundColor: theme.colors.primary,
  //  },
  //  container: {
  //    ...AmplifyTheme.container,
  //    backgroundColor: theme.colors.background,
  //  },
  //  sectionFooterLink: {
  //    ...AmplifyTheme.sectionFooterLink,
  //    color: theme.colors.primary,
  //  },
  //  buttonDisabled: {
  //    backgroundColor: theme.colors.disabled,
  //    alignItems: "center",
  //    padding: 16,
  //  },
  //};

  const signUpConfig = {
    header: "Create a new account",
    hideAllDefaults: true,
    signUpFields: [
      {
        label: "Email",
        key: "email",
        required: true,
        displayOrder: 1,
        type: "string",
      },
      {
        label: "Password",
        key: "password",
        required: true,
        displayOrder: 2,
        type: "password",
      },
    ],
  };

  const AppWithAuthenticator = withAuthenticator(App, {
    usernameAttributes: "email",
    //theme: amplifyTheme,
    signUpConfig,
  });

  return (
    <Authenticator.Provider>
      <Authenticator>
        <App />
      </Authenticator>
    </Authenticator.Provider>
  );
}

function AppWithTheme() {
  return (
    <ThemeProvider theme={theme}>
      <AppWithAuth />
    </ThemeProvider>
  );
}

export default AppWithTheme;
