import React from "react";
import { PlayerContext } from "../../contexts";
import { PlayerScoreHistory } from "../../components";

export function UserScoreHistory() {
  const playerDetails = React.useContext(PlayerContext);
  
  return (
    <PlayerScoreHistory sub={playerDetails?.player?.sub}/>
  );
}

