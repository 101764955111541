import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { View } from "react-native";
import { FAB } from "@rneui/base";
import { AppNavigator } from "./AppNavigator";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useTheme, ThemeMode, useThemeMode } from "@rneui/themed";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PlayerContext } from "../contexts";
export type RootStackParamList = {
  BottomTabNavigatorScreen: undefined;
  PostScore: undefined;
};

const RootStack = createNativeStackNavigator<RootStackParamList>();

export function RootNavigator() {
  const { theme } = useTheme();
  const { mode, setMode } = useThemeMode();
  const player = React.useContext(PlayerContext);
  const [isPlayerContextSet, setIsPlayerContextSet] = useState<boolean>(false);
  const [isPlayerSetup, setIsPlayerSetup] = useState<boolean>(false);

  const setThemeMode = async () => {
    try {
      const theme_mode = await AsyncStorage.getItem('@theme_mode') as ThemeMode;
      if (mode !== theme_mode) {
        if(theme_mode !== null) {
          setMode(theme_mode);
        } else {
          try {
            await AsyncStorage.setItem('@theme_mode', "light")
          } catch (e) {
            console.log(e);
          }
        }
      }
    } catch(e) {
      console.log(e);
    }
  }    

  useEffect(() => {
    setThemeMode();
    if (player?.player) {
      setIsPlayerContextSet(true);
    }
    if (player?.player?.scoreOverall) {
      console.log("Set True")
      setIsPlayerSetup(true);
    }
  }, [theme, player?.player, player?.player?.scoreOverall]);

  if (isPlayerContextSet) {
    if (isPlayerSetup) {
      return (
        <View style={{ flex: 1, backgroundColor: theme.colors.background }}>
          <NavigationContainer theme={{
            dark: theme.mode === 'dark' ? true : false,
            colors: {
              primary: theme.colors.black,
              background: theme.colors.background,
              card: theme.colors.grey5,
              text: theme.colors.black,
              border: theme.colors.grey5,
              notification: theme.colors.warning,
            }
          }}>
            <AppNavigator />
          </NavigationContainer>
        </View>
      );
    } else {}
  } else {
    return (
      <View style={{ flex: 1, backgroundColor: theme.colors.background, justifyContent: "center" }}>
          <FAB
            loading
            visible={true}
            color="green"
            icon={{ name: 'add', color: 'white' }}
            size="large"
          />
      </View>
    );
  }
  
}
