import React, { useEffect, useState } from "react";
import { Text, Button, Input } from "@rneui/themed";
import { View } from "react-native";
import { ScoreManualStyles } from "../../styles/ScoreManual";

export function DistributionStep({
  score,
  handleChange,
  errors,
  misses,
  nextStep,
  prevStep,
}: {
  score: any;
  handleChange: any;
  errors: any;
  misses: any;
  nextStep: any;
  prevStep: any;
}) {

  return (
    <>
      <View style={[ScoreManualStyles.stepHeader, { marginBottom: 5 }]}>
        <Text h2>Distribution</Text>
      </View>

      <View style={ScoreManualStyles.stepContent}>
        <Input
          keyboardType="numeric"
          containerStyle={ScoreManualStyles.input}
          label="One"
          renderErrorMessage={errors.one?.state || false}
          errorMessage={errors.one?.description || ""}
          onChange={(e: any) => handleChange(e, "one")}
          value={score.distribution.one.toString()}
        />
        <Input
          keyboardType="numeric"
          containerStyle={ScoreManualStyles.input}
          label="Two"
          renderErrorMessage={errors.two?.state || false}
          errorMessage={errors.two?.description || ""}
          onChange={(e: any) => handleChange(e, "two")}
          value={score.distribution.two.toString()}
        />
        <Input
          keyboardType="numeric"
          containerStyle={ScoreManualStyles.input}
          label="Three"
          renderErrorMessage={errors.three?.state || false}
          errorMessage={errors.three?.description || ""}
          onChange={(e: any) => handleChange(e, "three")}
          value={score.distribution.three.toString()}
        />
        <Input
          keyboardType="numeric"
          containerStyle={ScoreManualStyles.input}
          label="Four"
          renderErrorMessage={errors.four?.state || false}
          errorMessage={errors.four?.description || ""}
          onChange={(e: any) => handleChange(e, "four")}
          value={score.distribution.four.toString()}
        />
        <Input
          keyboardType="numeric"
          containerStyle={ScoreManualStyles.input}
          label="Five"
          renderErrorMessage={errors.five?.state || false}
          errorMessage={errors.five?.description || ""}
          onChange={(e: any) => handleChange(e, "five")}
          value={score.distribution.five.toString()}
        />
        <Input
          keyboardType="numeric"
          containerStyle={ScoreManualStyles.input}
          label="Six"
          renderErrorMessage={errors.six?.state || false}
          errorMessage={errors.six?.description || ""}
          onChange={(e: any) => handleChange(e, "six")}
          value={score.distribution.six.toString()}
        />
        <Input
          keyboardType="numeric"
          containerStyle={ScoreManualStyles.input}
          disableFullscreenUI={true}
          label="Misses"
          renderErrorMessage={errors.misses?.state || false}
          errorMessage={errors.misses?.description || ""}
          value={misses.toString()}
        />
      </View>

      <View style={ScoreManualStyles.stepFooter}>
        <View style={ScoreManualStyles.stepFooterButtons}>
          <Button onPress={(e) => prevStep()}>Previous</Button>
          <Button onPress={(e) => nextStep()}>Review</Button>
        </View>
      </View>
    </>
  );
}
