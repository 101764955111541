import React, { useState, useEffect } from "react";
import { Player } from "../API";
import { generateClient } from 'aws-amplify/api';
import * as queries from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

interface GroupPlayerContextInterface {
  player: Player | undefined;
  updatePlayer: (sub: string) => Promise<void>;
}

const GroupPlayerContext = React.createContext<GroupPlayerContextInterface | null>(null);

function GroupPlayerProvider({ children }: { children: any }) {
  const [player, setPlayer] = useState<Player>();

  const client = generateClient();

  const updatePlayer = async(sub: string) => {
    const result = (await client.graphql({
      query: queries.getPlayer,
      variables: {
        sub: sub,
      }
    })) as GraphQLResult<any>;
    setPlayer(result.data.getPlayer as Player);
  }

  return (
    <GroupPlayerContext.Provider value={{player, updatePlayer}}>{children}</GroupPlayerContext.Provider>
  );
}

export { GroupPlayerContext, GroupPlayerProvider };
