import { StyleSheet } from "react-native";

export const ScoreManualStyles = StyleSheet.create({
  stepHeader: {
    marginBottom: 30,
  },
  stepContent: {
    flex: 1,
    marginRight: 10,
  },
  stepFooter: {
    marginTop: 30,
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  stepFooterButtons: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  input: {
    margin: 10,
  },
  reviewLabel: {
    fontWeight: "bold",
  },
  reviewItem: {
    flexDirection: "row",
    flex: 1,
    margin: 3,
  }
});
