/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getGroup = /* GraphQL */ `query GetGroup($name: String!) {
  getGroup(name: $name) {
    name
    ownerSub
    owner {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        __typename
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            __typename
          }
          groupName
          group {
            name
            ownerSub
            __typename
          }
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
    scoresOverall {
      nextToken
      startedAt
      items {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    players {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            __typename
          }
          scoresOverall {
            nextToken
            startedAt
            __typename
          }
          players {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const getPlayer = /* GraphQL */ `query GetPlayer($sub: String!) {
  getPlayer(sub: $sub) {
    sub
    username
    email
    scoreOverall {
      playerSub
      number
      average
      rounds
      currentStreak
      maxStreak
      distribution {
        one
        two
        three
        four
        five
        six
        misses
        __typename
      }
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
    scoreHistory {
      nextToken
      startedAt
      items {
        playerSub
        number
        attemptNumber
        hardMode
        attempts {
          one {
            one
            two
            three
            four
            five
            __typename
          }
          two {
            one
            two
            three
            four
            five
            __typename
          }
          three {
            one
            two
            three
            four
            five
            __typename
          }
          four {
            one
            two
            three
            four
            five
            __typename
          }
          five {
            one
            two
            three
            four
            five
            __typename
          }
          six {
            one
            two
            three
            four
            five
            __typename
          }
          __typename
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    groups {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            __typename
          }
          scoresOverall {
            nextToken
            startedAt
            __typename
          }
          players {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPlayerQueryVariables, APITypes.GetPlayerQuery>;
export const listGroupPosts = /* GraphQL */ `query ListGroupPosts($groupName: String!, $nextToken: String) {
  listGroupPosts(groupName: $groupName, nextToken: $nextToken) {
    items {
      postId
      groupName
      playerSub
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      contentType
      content
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupPostsQueryVariables,
  APITypes.ListGroupPostsQuery
>;
export const listGroupScoresMonthly = /* GraphQL */ `query ListGroupScoresMonthly(
  $groupName: String!
  $month: String!
  $nextToken: String
) {
  listGroupScoresMonthly(
    groupName: $groupName
    month: $month
    nextToken: $nextToken
  ) {
    items {
      groupName
      playerSub
      month
      averageOverall
      averagePosted
      rounds
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupScoresMonthlyQueryVariables,
  APITypes.ListGroupScoresMonthlyQuery
>;
export const listPlayerScores = /* GraphQL */ `query ListPlayerScores($sub: String!, $nextToken: String) {
  listPlayerScores(sub: $sub, nextToken: $nextToken) {
    nextToken
    startedAt
    items {
      playerSub
      number
      attemptNumber
      hardMode
      attempts {
        one {
          one
          two
          three
          four
          five
          __typename
        }
        two {
          one
          two
          three
          four
          five
          __typename
        }
        three {
          one
          two
          three
          four
          five
          __typename
        }
        four {
          one
          two
          three
          four
          five
          __typename
        }
        five {
          one
          two
          three
          four
          five
          __typename
        }
        six {
          one
          two
          three
          four
          five
          __typename
        }
        __typename
      }
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlayerScoresQueryVariables,
  APITypes.ListPlayerScoresQuery
>;
export const listGroupInvites = /* GraphQL */ `query ListGroupInvites($groupName: String!) {
  listGroupInvites(groupName: $groupName) {
    items {
      playerSub
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      groupName
      group {
        name
        ownerSub
        owner {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        scoresOverall {
          nextToken
          startedAt
          items {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          __typename
        }
        players {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupInvitesQueryVariables,
  APITypes.ListGroupInvitesQuery
>;
export const listPlayerInvites = /* GraphQL */ `query ListPlayerInvites {
  listPlayerInvites {
    items {
      playerSub
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
            __typename
          }
          player {
            sub
            username
            email
            __typename
          }
          __typename
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            __typename
          }
          __typename
        }
        groups {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      groupName
      group {
        name
        ownerSub
        owner {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          scoreHistory {
            nextToken
            startedAt
            __typename
          }
          groups {
            nextToken
            startedAt
            __typename
          }
          __typename
        }
        scoresOverall {
          nextToken
          startedAt
          items {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            __typename
          }
          __typename
        }
        players {
          items {
            playerSub
            groupName
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlayerInvitesQueryVariables,
  APITypes.ListPlayerInvitesQuery
>;
