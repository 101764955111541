import React, { useState, useEffect, useContext } from "react";
import { Button, ThemeContext, ListItem, CheckBox } from "@rneui/themed";
import { View, GestureResponderEvent } from "react-native";
import * as mutations from "../../graphql/mutations";
import { StackScreenProps } from "@react-navigation/stack";
import { PlayerContext } from "../../contexts";
import { ProfileStackParamList } from "../../navigation/ProfileNavigator";
import { useStyles } from "../../styles";
import { NativeSyntheticEvent, TextInputChangeEventData } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';

type Props = StackScreenProps<ProfileStackParamList, "Notifications">;

export function Notifications({ navigation, route }: Props) {
  const playerDetails = React.useContext(PlayerContext);
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  const [dailyNotification, setDailyNotification] = useState<string | null>(null);
  const [groupPosts, setGroupPoasts] = useState<string | null>(null);

  useEffect(() => {
    async function getNotifications() {
      let notifications: Array<string> = [];
      try {
        notifications = JSON.parse(await AsyncStorage.getItem("@notifications") || "[]");
      } catch {}

      if (notifications.indexOf("daily") > -1) {
        setDailyNotification("daily");
      }
      if (notifications.indexOf("posts") > -1) {
        setGroupPoasts("posts");
      }
    }
    getNotifications();
  }, [playerDetails?.player?.username]);

  async function onSubmit(e: GestureResponderEvent) {
    e.preventDefault();
    try {
      await AsyncStorage.setItem("@notifications", JSON.stringify([dailyNotification, groupPosts].filter((element) => {
        return element !== null;
      })));
      await playerDetails?.updatePlayer();
      navigation.goBack();
    } catch (error: any) {
      console.log(error);
    }
  }

  return (
    <View style={[globalStyles.container, { width: "100%" }]}>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          width: "98%",
          maxWidth: 500,
          marginTop: 10,
        }}
      >
        <ListItem key="daily" bottomDivider>
          <ListItem.Content>
            <CheckBox
              right
              title="Daily Play Reminders"
              iconRight
              checked={dailyNotification === null ? false : true}
              textStyle={{ width: "100%", maxWidth: 600 }}
              onPress={() => setDailyNotification(dailyNotification === null ? "daily" : null)}
              containerStyle={{
                width: "100%",
                maxWidth: 800,
                alignContent: "space-between",
              }}
            />
          </ListItem.Content>
        </ListItem>
        <ListItem key="groupPosts" bottomDivider>
          <ListItem.Content>
            <CheckBox
              right
              title="Group Posts"
              iconRight
              checked={groupPosts === null ? false : true}
              textStyle={{ width: "100%", maxWidth: 600 }}
              onPress={() => setGroupPoasts(groupPosts === null ? "posts": null)}
              containerStyle={{
                width: "100%",
                maxWidth: 800,
                alignContent: "space-between",
              }}
            />
          </ListItem.Content>
        </ListItem>
        <Button onPress={(e: GestureResponderEvent) => onSubmit(e)}>
          Submit
        </Button>
      </View>
    </View>
  );
}
